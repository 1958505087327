export const formatNumber = (number,maxLength) => {
    const str = number.toString();
    if (str.length <= maxLength) {
        return str;
      } else {
        return str.substring(0, maxLength) + '...';
      }
}

/* 生成随机数 */
export const randomNumber =()=>{
	const data=["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
	let resuit=""
	for(let i=0;i<30;i++){
		resuit+=data[Math.floor(Math.random()*data.length)]
	}
	return resuit
}

export const checkDecimal=(value)=> {
  var regExp = /^\d+(\.\d{1,2})?$/; // 正则表达式，匹配至多两位小数的数字
  return regExp.test(value); // 返回匹配结果
}
