<template>
    <div class="container">
        <div>
            <el-card class="box-card" shadow="never">
                <template #header>
                    <div class="card-header">
                        <span>查询</span>
                    </div>
                </template>
                <div class="card-content">
                    <el-form :inline="true" label-width="100px" label-position="right">
                        <el-row :gutter="20">
                            <el-col :xs="24" :sm="12" :md="8">
                                <el-form-item label="流水号:" style="width: 280px">
                                    <el-input v-model="form.trade_no" placeholder="流水号" clearable />
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8">
                                <el-form-item label="支付方式:" style="width: 280px">
                                    <el-select v-model="form.payment_method_id" placeholder="全部" clearable>
                                        <el-option :value="2" label="微信" />
                                        <el-option :value="1" label="支付宝" />
                                        <el-option :value="3" label="云闪付" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8">
                                <el-form-item label="支付状态:" style="width: 280px">
                                    <el-select v-model="form.status" placeholder="全部" clearable>
                                        <el-option :value="'fail'" label="支付失败" />
                                        <el-option :value="3" label="支付成功" />
                                        <el-option :value="5" label="部分退款" />
                                        <el-option :value="6" label="全部退款" />
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :xs="24" :sm="12" :md="8">
                                <el-form-item label="金额:" style="width: 280px">
                                    <el-input v-model="form.total_amount" placeholder="金额" clearable />
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8">
                                <el-form-item label="交易时间:" style="width: 280px">
                                    <el-date-picker v-model="form.start_time" type="datetime"
                                        format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
                                        placeholder="请输入创建时间" />
                                    <el-date-picker v-model="form.end_time" type="datetime" format="YYYY-MM-DD HH:mm:ss"
                                        value-format="YYYY-MM-DD HH:mm:ss" placeholder="请输入截至时间"
                                        style="margin-top: 10px;" />
                                </el-form-item>
                            </el-col>

                            <el-col :xs="24" :sm="12" :md="8" style="display: flex; flex-direction: column; ">
                                <div style="margin-left: 100px;">
                                    <el-button type="primary" @click="handlSearch()"
                                        style="width: 120px;">查询</el-button>
                                </div>
                                <div style="margin-left: 100px; margin-top: 10px;">
                                    <el-button @click="handleReset()" style="width: 120px;">重置</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
        </div>
    </div>
    <div class="content">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="8" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>应收金额</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ parseFloat(statistic.pay_amount).toFixed(2) > 0 ?
                        parseFloat(statistic.pay_amount).toFixed(2) : 0 }}元</span>
                        </div>
                        <div>
                            <span>订单量</span>
                            <span>{{ statistic.pay_total > 0 ? statistic.pay_total : 0 }}笔</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>实收金额</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ (parseFloat(statistic.pay_amount) - parseFloat(statistic.refund_amount)).toFixed(2)
                        > 0
                        ? (parseFloat(statistic.pay_amount) - parseFloat(statistic.refund_amount)).toFixed(2) :
                        0
                                }}元</span>
                        </div>
                        <div>
                            <span>订单量</span>
                            <span>{{ statistic.pay_total > 0 ? statistic.pay_total : 0 }}笔</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>退款金额</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ statistic.refund_amount > 0 ? statistic.refund_amount : 0 }}元</span>
                        </div>
                        <div>
                            <span>退款金额</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>退款笔数</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ statistic.refund_total > 0 ? statistic.refund_total : 0 }}笔</span>
                        </div>
                        <div>
                            <span>退款笔数</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>手续费</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ statistic.fee_amount > 0 ? statistic.fee_amount : 0 }}元</span>
                        </div>
                        <div>
                            <span>手续费</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="4">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>结算金额</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>{{ (parseFloat(statistic.pay_amount) - parseFloat(statistic.refund_amount) -
                        parseFloat(statistic.fee_amount)).toFixed(2) > 0 ? (parseFloat(statistic.pay_amount) -
                            parseFloat(statistic.refund_amount) - parseFloat(statistic.fee_amount)).toFixed(2) : 0
                                }}元</span>
                        </div>
                        <div>
                            <span>订单量</span>
                            <span>{{ statistic.pay_total }}笔</span>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>

    <div class="detail">
        <el-card shadow="never" class="card-border" v-loading="loading">
            <template #header>
                <div class="card-header">
                    <span>订单明细</span>
                </div>
            </template>
            <div>
                <el-table :data="tableData" style="width: 100%" size="small">
                    <el-table-column prop="out_trade_no" label="订单号" width="120">
                        <template #default="{ row }">
                            <span @click="copyText(row.out_trade_no)">
                                <el-icon>
                                    <CopyDocument style="color:#52acff" />
                                </el-icon>
                            </span>
                            <el-tooltip class="item" effect="dark" :content="row.out_trade_no" placement="top">
                                <span @click="copyText(row.out_trade_no)">{{ row.out_trade_no.substr(0, 9) + '...'
                                    }}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="trade_no" label="流水号" width="120">
                        <template #default="{ row }">
                            <span @click="copyText(row.trade_no)">
                                <el-icon>
                                    <CopyDocument style="color:#52acff" />
                                </el-icon>
                            </span>
                            <el-tooltip class="item" effect="dark" :content="row.trade_no" placement="top">
                                <span @click="copyText(row.trade_no)">{{ row.trade_no.substr(0, 9) + '...' }}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="merchant_name" label="商户名称" width="160">
                    </el-table-column>
                    <el-table-column prop="start_time" label="交易时间" width="160">
                    </el-table-column>
                    <el-table-column prop="pay_amount" label="应收金额">
                    </el-table-column>
                    <el-table-column label="实收金额">
                        <template v-slot="scope">
                            {{ parseFloat(scope.row.pay_amount - scope.row.refund_amount).toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="refund_amount" label="退款金额">
                    </el-table-column>
                    <el-table-column prop="fee_amount" label="手续费">
                    </el-table-column>
                    <el-table-column label="结算金额">
                        <template v-slot="scope">
                            {{ parseFloat(scope.row.pay_amount - scope.row.fee_amount -
                        scope.row.refund_amount).toFixed(2) > 0 ? parseFloat(scope.row.pay_amount - scope.row.fee_amount -
                        scope.row.refund_amount).toFixed(2) :"0.00"}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="payment_method" label="订单类型">
                    </el-table-column>
                    <el-table-column label="状态" width="110">
                        <template #default="scope">
                            <el-tag v-if="scope.row.status_format == '支付成功'" type="success">{{ scope.row.status_format
                                }}</el-tag>
                            <el-tag v-else-if="scope.row.status_format == '支付失败'" type="danger">{{
                        scope.row.status_format }}</el-tag>
                            <el-tag v-else type="info">{{ scope.row.status_format }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template v-slot="scope">
                            <div class="handle" @click="getOrderDetail(scope.row.start_time, 'detail')"><text>详情</text>
                            </div>
                            <div v-if="scope.row.status_format == '支付成功'" class="handle"
                                @click="getOrderDetail(scope.row.start_time)"><text>退款</text></div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination :page-size="pagination.perPage" layout="prev, pager, next" :total="pagination.total"
                    @current-change="handleSizeChange">
                </el-pagination>
            </div>
        </el-card>
    </div>

    <div class="detail-card">
        <el-dialog v-model="detailVisible" title="订单详情" center>
            <template #default>
                <el-card shadow="never" class="detail-header">
                    <div class="detail-content">
                        <div class="orderDetail">
                            <span
                                v-if="orderDetails[0].refund_amount != 0 && orderDetails[0].refund_amount == orderDetails[0].pay_amount">支付状态:
                                <text class="message" style="background-color: red; ">全部退款</text></span>
                            <span
                                v-else-if="orderDetails[0].refund_amount != 0 && orderDetails[0].refund_amount != orderDetails[0].pay_amount">支付状态:
                                <text class="message" style="background-color: red; ">部分退款</text></span>
                            <span v-else-if="orderDetails[0].status_format == '支付失败'">支付状态:
                                <text class="message" style="background-color: red; ">失败</text></span>
                            <span v-else-if="orderDetails[0].status_format == '支付成功'">支付状态:
                                <text class="message" style="background-color:#67C23A;">成功</text></span>
                            <span v-else-if="orderDetails[0].status_format == '已撤销'">支付状态:
                                <text class="message" style="background-color:#909399;">已撤销</text></span>
                            <span>共计金额:<text>{{ orderDetails[0].pay_amount }}</text></span>
                            <span>创建时间:<text>{{ ConversionTime(orderDetails[0].created_at) }}</text></span>
                            <span>支付通道:<text>{{ orderDetails[0].payment_channel }}</text></span>
                            <span>订单号:<text>{{ orderDetails[0].out_trade_no }}</text></span>
                            <span>备注:<text>{{ orderDetails[0].remarks == null ? '' :
                        orderDetails[0].remarks }}</text></span>
                        </div>
                        <div class="orderDetail">
                            <span>商户ID:<text>{{ orderDetails[0].merchant_no }}</text></span>
                            <span
                                v-if="orderDetails[0].status_format == '部分退款' || orderDetails[0].status_format == '已退款'">退款金额:<text>{{
                        orderDetails[0].refund_amount }}</text></span>
                            <span>支付方式:<text>{{ orderDetails[0].payment_method }}</text></span>
                            <span>支付时间:<text>{{ ConversionTime(orderDetails[0].updated_at) }}</text></span>
                            <span>流水号:<text>{{ orderDetails[0].trade_no }}</text></span>
                            <span>终端号:<text>{{ orderDetails[0].terminal == null ? '' : orderDetails[0].terminal.id
                                    }}</text></span>
                        </div>

                    </div>
                </el-card>
            </template>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="detailVisible = false">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>

    <div class="refund-card">
        <el-dialog v-model="refundVisible" title="退款" center width="50%">
            <template #default>

                <el-card shadow="never" class="refund-header">
                    <el-alert title="您正在进行退款操作,请认真核对退款订单信息！" type="warning" center show-icon
                        style="margin-bottom: 20px;" />
                    <div class="refund-content">
                        <div class="refund-ipt">
                            <div class="refund">
                                <span><text>退款金额:</text></span>
                                <span>
                                    <el-tooltip effect="dark" placement="top-start">
                                        <template #content>当前订单号：{{ orderDetails[0].out_trade_no }}</template>
                                        <el-input size="large" placeholder="请输入退款金额" v-model="refundForm.amount"
                                            @blur="amountBlur(orderDetails[0].pay_amount)" />
                                    </el-tooltip>
                                </span>
                                <span>
                                    <el-tooltip effect="dark" placement="top-start">
                                        <template #content>可退款金额：{{ orderDetails[0].pay_amount }}</template>
                                        <text style="color: #52acff;cursor: pointer; margin-left: 10px;"
                                            @click="allRefund(orderDetails[0].pay_amount)">全部退款</text>
                                    </el-tooltip>
                                </span>
                            </div>
                            <div class="tips" v-if="tips != ''" style="color: red; font-size: 14px;">{{ tips }}</div>
                        </div>
                        <div class="refund-ipt">
                            <div class="refund">
                                <span><text>退款密码:</text></span>
                                <span><el-input size="large" placeholder="请输入退款密码" v-model="refundForm.password"
                                        type="password" @blur="passwordBlur()" /></span>
                            </div>
                            <div class="tips" v-if="passwordTips != ''" style="color: red; font-size: 14px;">{{
                        passwordTips }}</div>
                        </div>
                    </div>
                </el-card>
            </template>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="handleCancle">
                        取消
                    </el-button>
                    <el-button type="primary" @click="handleRefund(orderDetails[0])">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref } from "vue";
import { GetTodayStartTime, GetTodayEndTime, ConversionTime } from "../../tools/dateTools"
import { randomNumber, checkDecimal } from "../../tools/utils"
import { ElMessage, ElNotification } from 'element-plus'
import { CopyDocument } from '@element-plus/icons-vue'

const { proxy } = getCurrentInstance()
const tableData = ref([])
const loading = ref(true)
const statistic = ref({})
const detailVisible = ref(false)

const pagination = ref({
    total: 0,
    perPage: 1,
})

const form = ref({
    start_time: GetTodayStartTime(),
    end_time: GetTodayEndTime(),
    store_id: '',
    payment_method_id: '',
    status: '',
    machine: '',
    trade_no: '',
    out_trade_no: '',
    total_amount: '',
    refund_no: '',
    page: 1,
    pageSize: 15
})
const refundForm = ref({
    amount: '',
    password: ''
})

onMounted(() => {

    getOrderList(form.value)
    getOrderStatistic(form.value)
})
//查询
const handlSearch = () => {
    pagination.value.total = 1;
    form.value.page = 1;
    getOrderStatistic(form.value)
    getOrderList(form.value)
}
//重置
const handleReset = () => {
    form.value.start_time = GetTodayStartTime();
    form.value.end_time = GetTodayEndTime();
    form.value.payment_method_id = '';
    form.value.status = '';
    form.value.total_amount = '';
    form.value.trade_no = '';

}
//复制
const copyText = (data) => {
    const input = document.createElement('input');
    input.value = data;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    ElMessage({
        message: '复制成功',
        type: 'success',
    })
};

//表格数据
const getOrderList = async (data) => {
    loading.value = true
    await proxy.$api.order.getOrderList(data).then(res => {
        if (res.status == 200 && res.success) {
            tableData.value = res.data
            pagination.value = res.pagination
        }
        loading.value = false
    })
}

//订单统计
const getOrderStatistic = async (data) => {
    loading.value = true
    await proxy.$api.order.getOrderStatistic(data).then(res => {
        if (res.status == 200 && res.success) {
            statistic.value = res.data
        }
        loading.value = false
    })
}


//获取下一页
const handleSizeChange = (val) => {
    form.value.page = val
    getOrderList(form.value)
}
//详情
const orderDetails = ref({})
const getOrderDetail = async (data, type) => {
    await proxy.$api.order.getOrderList({ start_time: data, end_time: data, status: form.value.status }).then(res => {
        if (res.status == 200 && res.success) {
            if (type == 'detail') {
                detailVisible.value = true
                orderDetails.value = res.data
            } else {
                refundVisible.value = true
                orderDetails.value = res.data
            }
        }
    })
}


const tips = ref('')
const amountBlur = (data) => {
    tips.value = ''
    if (refundForm.value.amount == '') {
        tips.value = '*退款金额不能为空'
    } else if (checkDecimal(refundForm.value.amount) === false) {
        tips.value = '*退款金额请规范到两位小数'
    } else if (refundForm.value.amount > data) {
        tips.value = '*退款金额不能大于支付金额'
    } else {
        return true
    }
}
const passwordTips = ref('')
const passwordBlur = () => {
    passwordTips.value = ''
    if (refundForm.value.password == '') {
        passwordTips.value = '*密码不能为空'
    } else {
        return true
    }
}

//退款
const refundVisible = ref(false)
//全部退款金额
const allRefund = (data) => {
    tips.value = ''
    refundForm.value.amount = data
}
const handleCancle = () => {
    refundVisible.value = false
    tips.value = ''
    refundForm.value.amount = ''
    refundForm.value.password = ''
    passwordTips.value = ''
}
const handleRefund = async (data) => {
    let orderdata = {
        sign: randomNumber(),
        refund_password: refundForm.value.password,
        terminal_id: data.terminal.id,
        serial_number: data.serial_number,
        out_refund_no: data.out_trade_no,
        trade_no: data.trade_no,
        remarks: data.remarks,
        refund_amount: refundForm.value.amount,
    }
    if (amountBlur() != true && passwordBlur() != true) {
        ElNotification({
            title: 'Error',
            message: '请先完成填写',
            type: 'error',
        })
    } else {
        let paymentPromise = orderdata.terminal_id === '' ? proxy.$api.order.drawback(orderdata) : proxy.$api.order.drawbackV2(orderdata);
        await paymentPromise.then(res => {
            if (res.status == 200 && res.success) {
                refundVisible.value = false
                getOrderList(form.value)
                getOrderStatistic(form.value)
                ElNotification({
                    title: 'Success',
                    message: '退款成功',
                    type: 'success',
                })
            }
        })
    }

}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;

    .box-card {
        border-top: 5px solid #e7e7e7;
    }

    .card-header {
        span {
            font-size: 15px;
            font-weight: bold;
        }
    }
}

.content {
    margin-top: 20px;
    white-space: nowrap;
    overflow: hidden;

    .card-border {
        border-top: 5px solid #e7e7e7;
        margin-top: 10px;

        span {
            font-size: 15px;
            font-weight: bold;
        }
    }

    .card-content {
        box-sizing: border-box;

        h2 {
            span {
                font-size: 16px;
                margin-left: 6px;
            }
        }

        div:nth-child(1) {
            font-size: 23px;
            padding-left: 6px;
        }

        div:nth-child(2) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;

            span {
                font-size: 14px;
                margin-left: 6px;
            }

            span:nth-child(2) {
                color: #409EFF;
            }
        }
    }
}

.detail {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            span {
                font-size: 15px;
                font-weight: bold;
            }
        }

        .handle {
            text {
                color: #409EFF;
                cursor: pointer;
            }

            text:hover {
                color: #ccc;
            }
        }

        .pagination {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.detail-card {
    .detail-content {
        display: flex;
        flex-direction: row;

        .orderDetail {
            display: flex;
            flex: 1;
            flex-direction: column;

            span:not(:first-child) {
                margin-top: 20px;
            }

            span {
                text {
                    margin-left: 10px;
                }

                .message {
                    color: #fff;
                    padding: 5px;
                    border-radius: 5px;
                }
            }
        }
    }
}

.refund-card {
    overflow: hidden;
    white-space: nowrap;

    .refund-header {
        .refund-content {
            display: flex;
            flex-direction: column;
            padding-left: 30%;
            padding-bottom: 20px;

            .refund-ipt {
                display: flex;
                flex-direction: column;

                .refund {
                    display: flex;
                    height: 40px;
                    line-height: 40px;
                }

            }

            .tips {
                margin-left: 60px;
            }

            .refund-ipt:nth-child(2) {

                margin-top: 30px;
            }

        }
    }
}
</style>